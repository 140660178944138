import React, { useContext } from 'react';
import { Anchor, Box, Button, Text, ThemeContext } from 'grommet';
import { connect } from 'react-redux';
import { Edit } from 'grommet-icons';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
import Avatar from 'granite-admin/core/components/Avatar';
import { getTenantAccess } from 'granite-admin/utils/auth-singleton'; //getAuthToken, resetTenantAccess,
// import { setCookie } from 'granite-admin/utils/storage';
import Divider from 'granite-admin/core/components/Divider';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
// import { getCookie, deleteCookie } from 'granite-admin/utils/storage';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import { useToast } from 'granite-admin/core/components/Toast';

// import useWebSocket from 'common/useWebSocket';
// import { onConnectionOpen, onConnectionClose } from 'common/CommonFunction.js';

import sendCustomEmails from '../gateways/routeMessage-api';

import { env } from 'granite-admin/env';
const currentDomain = window.location;
// const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';

// const StyledBox = styled(Box)`
//   &:hover {
//     background: ${props => props.theme.global.colors['hover']};
//     button {
//       visibility: visible;
//     }
//   }
// `;
// const StyledButton = styled(Button)`
//   visibility: hidden;
// `;
const getUserAvatar = (name, image, size) => {
  if (Boolean(name) === false) return;

  return (
    <Box flex="grow">
      <Avatar src={image ? image : null} width={size} height={size} round="full">
        <Text color="white" size="small">
          {!image && name.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
    </Box>
  );
};
// const projectName = process.env.REACT_APP_PROJECT_NAME || 'Granite';

const CompanyDrop = ({
  navigate,
  // organisations,
  selectedOrganisation,
  userProfile,
  // dispatch,
  signOut,
  showMyBoardBtn,
  backToBusiness,
}) => {
  // const { websocketInstance: nativeWebsocketInstance } = useWebSocket({
  //   onConnectionClose,
  //   onConnectionOpen,
  //   // onGetMessage,
  // });
  const theme = useContext(ThemeContext);
  const config = useContext(ConfigContext);
  const { errorToast } = useToast();
  const { showCreateOrganisation } = config ?? {};
  const tenantAccess = getTenantAccess();
  const curServer = currentDomain?.origin.includes('test')
    ? '.testing'
    : currentDomain?.origin.includes('staging')
    ? '.staging'
    : '';
  const url = `${window.location.protocol}//${selectedOrganisation?.domain}${curServer}.rollcall.com.au/admin#/force-login`;

  // const switchOrganisation = organisation => {
  //   resetTenantAccess();
  //   const currentSubdomain = window.location?.host.split('.')[0];
  //   deleteCookie('organisationData');
  //   deleteCookie('BAorigin');
  //   deleteCookie('redirectDomain');
  //   if (config?.subdomainRedirectEnabled && organisation?.domain && organisation?.domain !== currentSubdomain) {
  //     if (nativeWebsocketInstance) nativeWebsocketInstance.close();
  //     const host = window.location.host;
  //     const domain = host.substring(
  //       host.indexOf('.') + 1,
  //       host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  //     );
  //     const bussinessDomain = host.substring(
  //       0,
  //       host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  //     );
  //     if (selectedOrganisation?.pk) {
  //       setCookie('organisationData', JSON.stringify(organisation), 1, domain);
  //       setCookie('BAorigin', window.origin, 1, domain);
  //       setCookie('redirectDomain', 'yes', 1, domain);
  //     } else {
  //       setCookie('organisationData', JSON.stringify(organisation), 1, bussinessDomain);
  //       setCookie('BAorigin', window.origin, 1, bussinessDomain);
  //       setCookie('redirectDomain', 'yes', 1, bussinessDomain);
  //     }
  //     const authToken = getAuthToken('authToken');

  //     if (userProfile.isSystemAdmin) {
  //       window.location = `${window.location.protocol}//${organisation.domain}.${
  //         selectedOrganisation?.pk ? domain : bussinessDomain
  //       }${
  //         window.location.port ? `:${window.location.port}` : ''
  //       }/login?access_token=${authToken}&redirectDomain=yes&tenantAccess=true`;
  //     } else {
  //       localStorage.clear();
  //       window.location = `${window.location.protocol}//${organisation.domain}.${domain}${
  //         window.location.port ? `:${window.location.port}` : ''
  //       }/login?access_token=${authToken}&redirectDomain=yes&tenantAccess=true`;
  //     }
  //   } else if (config.subdomainRedirectEnabled && getCookie('redirectDomain') === 'yes') {
  //     const URL = `${getCookie('BAorigin')}/login`;
  //     const host = window.location.host;
  //     const domain = host.substring(
  //       host.indexOf('.') + 1,
  //       host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  //     );
  //     setCookie('organisationData', '', 1, domain);
  //     setCookie('BAorigin', '', 1, domain);
  //     setCookie('redirectDomain', '', 1, domain);
  //     localStorage.clear();
  //     window.location = URL;
  //   } else {
  //     dispatch({
  //       type: 'organisations/UPDATE_SELECTED_ORGANISATION',
  //       data: organisation,
  //     });
  //     dispatch({
  //       type: 'accounts/sidebar/UPDATE_SIDEBAR',
  //       data: [],
  //     });
  //     document.title = `${projectName} - ${organisation?.name} `;
  //     navigate('/dashboard');
  //   }
  // };

  const switchToOldPlatform = async () => {
    try {
      const alertEmailsList = userProfile?.redirectionAlertsEmails
        ?.replaceAll(/\s/g, '')
        ?.replaceAll('|', ' ')
        ?.replaceAll(',', ' ')
        ?.split(' ');
      await alertEmailsList?.forEach(email =>
        sendCustomEmails.sendCustomEmail({
          email: email,
          message: `${userProfile?.firstName + ' ' + userProfile?.lastName} is trying to access the old platform for ${
            selectedOrganisation?.name
          } school.`,
        }),
      );
      if (url) window.location.replace(url);
    } catch (e) {
      if (e.errors) errorToast(e?.errors?.title || 'Redirected to old platform fail');
    }
  };

  // const canUserCreateOrg = process.env.REACT_APP_CAN_USER_CREATE_ORGANIZATION === 'true';
  const termsLink = env.REACT_APP_TERMS_URL || process.env.REACT_APP_TERMS_URL;
  const privacyLink = env.REACT_APP_PRIVACY_URL || process.env.REACT_APP_PRIVACY_URL;

  return (
    <Box background="sidebar-drops" pad={{ top: 'xsmall', bottom: 'medium' }} width="medium" flex={{ shrink: 0 }}>
      <Box justify="between" direction="row" margin={{ horizontal: 'small' }}>
        <Button
          primary
          size="small"
          label={<Text size="small">Switch</Text>}
          tip="Redirect to old platform"
          onClick={() => switchToOldPlatform()}
        />
        <Link to="/profile">
          <Button tip="Edit Profile" icon={<Edit color="brand" size="small" />} plain />
        </Link>
      </Box>
      <Box align="center" gap="small">
        {getUserAvatar(userProfile.firstName, userProfile.profileImage, 'xxsmall')}
        <Text>{userProfile.firstName + ' ' + userProfile.lastName}</Text>
        {selectedOrganisation?.pk && (
          <Box gap="small" align="center" margin={{ horizontal: 'small' }}>
            <Text>Org Id: {selectedOrganisation.pk}</Text>
            <Button
              label={selectedOrganisation.name}
              onClick={() =>
                userProfile.permissionsName.indexOf('organisation_write') > -1 &&
                userProfile.permissionsName.indexOf('school_admin_read') === -1 &&
                navigate('/company-settings')
              }
              primary
              color={theme?.userProfileDrop?.button ?? 'text'}
            />
          </Box>
        )}
      </Box>

      <Box margin={{ vertical: 'medium' }}>
        <Divider
          style={{ width: 'auto', minWidth: 'auto' }}
          margin={{ horizontal: 'small' }}
          color="none"
          background={{ color: 'border', opacity: 'medium' }}
        />
        {/* {organisations
          ?.filter(org => org.pk !== selectedOrganisation.pk)
          ?.map((org, i) => {
            return (
              <React.Fragment key={i}>
                <StyledBox margin={{ vertical: 'xsmall' }} pad="small" direction="row" justify="between" align="center">
                  <Box pad={{ horizontal: 'small' }} direction="row" align="center" gap="medium">
                    {getUserAvatar(org.name, undefined, '25px')}
                    <Box>
                      <Text>{org.name}</Text>
                      <Text>Org Id: {org.pk}</Text>
                    </Box>
                  </Box>
                  <StyledButton
                    primary
                    size="small"
                    label={<Text size="small">Switch</Text>}
                    onClick={() => switchOrganisation(org)}
                  />
                </StyledBox>
                <Divider
                  color="none"
                  style={{ width: 'auto', minWidth: 'auto' }}
                  margin={{ horizontal: 'small' }}
                  background={{ color: 'border', opacity: 'medium' }}
                />
              </React.Fragment>
            );
          })} */}
      </Box>
      <Box align="center" gap="small">
        <Box direction="row" gap="small">
          {showMyBoardBtn && userProfile.isSystemAdmin && selectedOrganisation?.pk && !tenantAccess && (
            <Button
              label="My Board"
              onClick={backToBusiness}
              primary
              color={theme?.userProfileDrop?.button ?? 'text'}
            />
          )}
          <Button label="Logout" onClick={signOut} primary color={theme?.userProfileDrop?.button ?? 'text'} />
        </Box>
        {userProfile?.isSystemAdmin && showCreateOrganisation && !selectedOrganisation?.pk && (
          <Link to="/get-started">
            <Anchor as="span" size="small" color="info" label="Create Organization" />
          </Link>
        )}
        <Box direction="row" gap="medium">
          {termsLink && (
            <Link onClick={() => window.open(termsLink)} to="#">
              <Anchor as="span" size="small" color="info" label="Terms and Conditions" />
            </Link>
          )}
          {privacyLink && (
            <Link onClick={() => window.open(privacyLink)} to="#">
              <Anchor as="span" size="small" color="info" label="Privacy Policy" />
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CompanyDrop.propTypes = {
  navigate: PropTypes.object,
  signOut: PropTypes.func,
  dispatch: PropTypes.func,
  userProfile: PropTypes.object,
  organisations: PropTypes.array,
  userOrganisations: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  showMyBoardBtn: PropTypes.bool,
  backToBusiness: PropTypes.func,
};

CompanyDrop.defaultProps = {
  backToBusiness: () => {},
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  organisations: OrganisationDucks.organisations(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
});
const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDrop);
