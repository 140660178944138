import React from 'react';
import { Box, Button, CheckBox } from 'grommet';

const EVENTGROUPS_COLUMNS = [
  {
    id: 'group_name', accessor: 'groupName', Header: 'Name', hideColumnsLabel: 'Name', width: 85,
    minWidth: 85
  },
  {
    id: 'inportal',
    accessor: 'inPortal',
    Header: 'In Portal',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'in_portal', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'In Portal',
    width: 60,
    minWidth: 60
  },
  {
    id: 'restrictmultiple',
    accessor: 'restrictmultiple',
    Header: 'Restrict Multiple',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'one_student_one_route', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Restrict Multiple',
    width: 100,
    minWidth: 100
  },
  {
    id: 'parentcanseeallstops',
    accessor: 'showAllStops',
    Header: 'Parent Can See All Stops',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'show_all_stops', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Parent Can See All Stops',
    width: 120,
    minWidth: 120
  },
  {
    id: 'campusstopchecks',
    accessor: 'campusStopCheck',
    Header: 'Campus Stop Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'campus_stop_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Campus Stop Check',
    width: 120,
    minWidth: 120
  },
  {
    id: 'buschecks',
    accessor: 'busCheck',
    Header: 'Bus check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'bus_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Bus check',
    width: 65,
    minWidth: 65
  },
  {
    id: 'busoffchecks',
    accessor: 'busoffchecks',
    Header: 'Bus Off Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'bus_off_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: '',
    width: 100,
    minWidth: 100
  },
  {
    id: 'supervisorchecks',
    accessor: 'supervisorchecks',
    Header: 'Supervisor Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'supervisor_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Supervisor Check',
    width: 110,
    minWidth: 110
  },
  {
    id: 'supervisoroffchecks',
    accessor: 'supervisoroffchecks',
    Header: 'Supervisor Off Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'supervisor_off_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'Supervisor Off Check',
    width: 125,
    minWidth: 125
  },
  {
    id: 'schoolchecks',
    accessor: 'schoolCheck',
    Header: 'School Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'school_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'School Check',
    width: 90,
    minWidth: 90
  },
  {
    id: 'schooloffchecks',
    accessor: 'schoolOffCheck',
    Header: 'School Off Check',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => toggleCheckbox({ key: 'school_off_check', value: e.target.checked, pk: row.original.pk })}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'School Off Check',
    width: 105,
    minWidth: 105
  },
  {
    id: 'amdisplay',
    accessor: 'amDisplay',
    Header: 'AM Display',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => {
            toggleCheckbox({ key: 'am_display', value: e.target.checked, pk: row.original.pk });
          }}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'AM Display',
    width: 70,
    minWidth: 70
  },
  {
    id: 'pmdisplay',
    accessor: 'pmDisplay',
    Header: 'PM Display',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => {
            toggleCheckbox({ key: 'pm_display', value: e.target.checked, pk: row.original.pk });
          }}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'PM Display',
    width: 70,
    minWidth: 70
  },
  {
    id: 'allday',
    accessor: 'allDay',
    Header: 'All Day',
    Cell: ({ cell: { value, row }, status, toggleCheckbox, ...rest }) => {
      return (
        <CheckBox
          color="#e18940"
          checked={value}
          onChange={e => {
            toggleCheckbox({ key: 'all_day', value: e.target.checked, pk: row.original.pk });
          }}
        />
      );
    },
    disableSortBy: true,
    hideColumnsLabel: 'All Day',
    width: 70,
    minWidth: 70
  },
  {
    accessor: 'actions',
    Header: 'Actions',
    Cell: ({ cell: { row }, openActionLayer, ...rest }) => {
      return (
        <Box direction="row" gap="10px" width="100px" focusIndicator={false}>
          <Button
            style={{
              padding: '3px',
              border: '1px solid #023a60',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={() => openActionLayer(row.original)}
            label="ACTIONS"
            primary
            color="brand"
          />
        </Box>
      );
    },
    disableSortBy: true,
    width: 70,
    minWidth: 70
  },
];

const EVENTGROUPS_EDIT_EVENTS = {
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  SAVE_FAILURE: 'SAVE_FAILURE',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
  FETCH_EVENT_GROUP: 'FETCH_EVENT_GROUP',
};

export { EVENTGROUPS_COLUMNS, EVENTGROUPS_EDIT_EVENTS };
