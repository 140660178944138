import React from 'react';
import { Text, Box, Spinner } from 'grommet';
import ActionsCell from 'common/ActionsCell';

const capitalizeString = (str = '') => {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};
function readWidthValues(id) {
  const previousWidths = JSON.parse(localStorage.getItem('transport_columns') || '{}');
  return previousWidths?.[id];
}

function transportColumns() {
  const TRANSPORT_COLUMNS = [
    {
      id: 'studentName',
      accessor: 'studentName',
      Header: 'NAME',
      hideColumnsLabel: 'NAME',
      Cell: ({ cell: { value, row } }) => {
        const color =
          row?.original?.status === 'OFF' ? '#27c24c' : row?.original?.status === 'ON' ? '#23b7e5' : '#575757';
        return (
          <Box
            align="start"
            justify="center"
            margin={{ left: '3px' }}
            onClick={() => window.open(`/student?pk=${row.original?.pk}`)}
            focusIndicator={false}
          >
            <Text style={{ color, whiteSpace: 'break-spaces', fontWeight: 'bold' }}>{value}</Text>
          </Box>
        );
      },
      // disableSortBy: true,
      width: readWidthValues('studentName') || 220,
    },
    {
      accessor: 'actions',
      Header: 'ACTIONS',
      Cell: ({
        cell: { row },
        original,
        listLocationHandler,
        editHandler,
        deleteHandler,
        currentDate,
        disableEvent,
        ...rest
      }) => {
        let value = [];
        value.push({
          name: 'edit',
          color: '#023a60',
          onClick: e => {
            e.stopPropagation();
            editHandler(row?.original);
          },
        });
        value.push({
          color: '#023a60',
          name: 'location',
          onclick: listLocationHandler,
          margin: 0,
          style: { paddingTop: '0.5rem' },
        });
        value.push({
          name: 'delete',
          color: '#023a60',
          onClick: () => deleteHandler(row?.original),
        });
        // if (value?.length && !value?.find?.(elem => elem.name === 'location'))
        //   value.push({ color: '#023a60', name: 'location', onclick: listLocationHandler });
        if (currentDate && disableEvent) {
          value = value?.filter(e => e?.name === 'location');
        }
        if (row?.original?.isEditable === false && value?.[0] && value?.[1]) {
          // value[0].disabled = true;
          // value[1].disabled = true;
          value = value.filter(e => e.name === 'location');
        }

        // if (currentDate && Date.parse(currentDate) < Date.parse(new Date().toISOString().slice(0, 10))) {
        //   value = value.filter(e => e.name !== 'edit');
        // }
        if (row?.original?.name === 'NO STOP ALLOCATED' && !row?.original?.status) {
          const loc = value.find(e => e.name === 'location') || {};
          const editIcon = value.filter(e => e.name === 'edit') || {};
          const delIcon = value.filter(e => e.name === 'delete') || {};

          value = [...editIcon, { ...loc, disabled: true }, ...delIcon];
        }

        return (
          <Box pad={value?.length === 1 ? { left: '30px' } : {}} gap="small">
            <ActionsCell {...rest} data={value} row={row} />
          </Box>
        );
      },
      disableSortBy: true,
      width: readWidthValues('actions') || 75,
      // width: 75,
    },
    {
      accessor: 'status',
      Header: 'STATUS',
      width: readWidthValues('status') || 70,
      // width: 70,
      // disableSortBy: true,
    },
    {
      id: 'name',
      accessor: 'name',
      Header: 'STOP',
      hideColumnsLabel: 'STOP',
      Cell: ({ cell: { value, row } }) => {
        // const color = row?.original?.status === "OFF" ? "#27c24c" : row?.original?.status === "ON" ? "#23b7e5" : ''
        const color = row?.original?.routeColor || '';
        const bgColor = row?.original?.route_action_performed === 'EXIT' ? row?.original?.routeColor : '';
        return (
          <Box align="center" justify="start" direction="row" gap="3px" flex={{ shrink: 0 }}>
            <Box
              flex={{ shrink: 0 }}
              style={{
                width: '17px',
                height: '17px',
                border: `1px solid ${color}`,
                textAlign: 'center',
                verticalAlign: 'center',
                color: row?.original?.route_action_performed === 'EXIT' ? 'white' : color,
              }}
              background={bgColor}
              justify="center"
              round="50%"
            >
              {row?.original?.stopOrder || 0}{' '}
            </Box>
            <Text style={{ whiteSpace: 'break-spaces' }}>
              {value + (row?.original?.stopArrivalTime ? ' - ' + row?.original?.stopArrivalTime : '')}
            </Text>
          </Box>
        );
      },
      width: readWidthValues('name') || 180,
      // disableSortBy: true,
    },
    {
      id: 'scanOn',
      accessor: 'scanOn',
      Header: 'SCAN ON',
      hideColumnsLabel: 'SCAN ON',
      // disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return (
          <Box align="center" justify="start" direction="row" pad="small">
            <Box gap="2px">
              {value?.map?.(val => (
                <Text style={{ whiteSpace: 'pre-line' }}>{capitalizeString(val)}</Text>
              ))}
            </Box>
            {/* prettier-ignore */}
            {value !== '-' &&
            (!row?.original?.noCardExceptions?.length
              ? row?.original?.exception?.toLowerCase?.() === 'no card' ||
                row?.original?.exception.some(function (element) {
                  return element.toLowerCase() === 'no card';
                })
              : row?.original?.noCardExceptions?.toLowerCase?.() === 'no card scan on' ||
                row?.original?.noCardExceptions.some(function (element) {
                  return element.toLowerCase() === 'no card scan on';
                })) ? (
              <Text
                size="10px"
                style={{
                  backgroundColor: '#FF9E37',
                  borderRadius: '50%',
                  padding: '3px',
                  color: '#ffffff',
                  marginLeft: '2px',
                }}
              >
                NC
              </Text>
            ) : null}
          </Box>
        );
      },
      width: readWidthValues('scanOn') || 220,
    },
    {
      id: 'scanOff',
      accessor: 'scanOff',
      Header: 'SCAN OFF',
      hideColumnsLabel: 'SCAN OFF',
      // disableSortBy: true,
      Cell: ({ cell: { value, row } }) => {
        return (
          <Box align="center" justify="start" direction="row" pad="small">
            <Box gap="2px">
              {value?.map?.(val => (
                <Text style={{ whiteSpace: 'pre-line' }}>{capitalizeString(val)}</Text>
              ))}
            </Box>
            {/* prettier-ignore */}
            {value !== '-' &&
            (!row?.original?.noCardExceptions?.length
              ? row?.original?.exception?.toLowerCase?.() === 'no card' ||
                row?.original?.exception.some(function (element) {
                  return element.toLowerCase() === 'no card';
                })
              : row?.original?.noCardExceptions?.toLowerCase?.() === 'no card scan off' ||
                row?.original?.noCardExceptions.some(function (element) {
                  return element.toLowerCase() === 'no card scan off';
                })) ? (
              <Text
                size="10px"
                style={{
                  backgroundColor: '#FF9E37',
                  borderRadius: '50%',
                  padding: '3px',
                  color: '#ffffff',
                  marginLeft: '2px',
                }}
              >
                NC
              </Text>
            ) : null}
          </Box>
        );
      },
      width: readWidthValues('scanOff') || 220,
    },
    {
      id: 'exception',
      accessor: 'exception',
      Header: 'EXCEPTION',
      hideColumnsLabel: 'EXCEPTION',
      Cell: ({ cell: { value } }) => {
        return (
          <Box align="start" justify="center">
            {value?.length
              ? value?.map(exp => (
                  <Box direction="row" align="center" style={{ textTransform: 'uppercase' }}>
                    {exp}
                  </Box>
                ))
              : value}
            {/* {value?.toLowerCase?.() === 'no card' || value?.includes?.('No Card') ? (
            <Box direction="row" align="center">
              No Card
            </Box>
          ) : (
            value
          )} */}
          </Box>
        );
      },
      // disableSortBy: true,
      width: readWidthValues('exception') || 220,
    },
    {
      id: 'amountCharged',
      accessor: 'amountCharged',
      Header: 'AMOUNT CHARGED',
      hideColumnsLabel: 'Amount Charged',
      Cell: ({ cell: { value }, chargeSpinner }) => {
        return (
          <Box align="start" justify="center">
            {!chargeSpinner ? value : <Spinner size="xsmall" />}
          </Box>
        );
      },
      width: readWidthValues('studentName') || 220,
    },
    {
      id: 'reasonOfCharge',
      accessor: 'reasonOfCharge',
      Header: 'REASON OF CHARGE',
      hideColumnsLabel: 'Reason of Charge',
      Cell: ({ cell: { value }, chargeSpinner }) => {
        return (
          <Box align="start" justify="center">
            {!chargeSpinner ? value : <Spinner size="xsmall" />}
          </Box>
        );
      },
      width: readWidthValues('studentName') || 220,
    },
  ];
  return TRANSPORT_COLUMNS;
}

const TRANSPORT_EVENTS = {
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
  SAVE_FAILURE: 'SAVE_FAILURE',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
  FETCH_ROUTE: 'FETCH_ROUTE',
  FETCH_EVENT_GROUP: 'FETCH_EVENT_GROUP',
  // FETCH_ROUTE: 'FETCH_ROUTE',
  FETCH_BUS_ABSENTEE: 'FETCH_BUS_ABSENTEE',
  FETCH_STUDENT_BUS_GROUP: 'FETCH_STUDENT_BUS_GROUP',
  POST_FAILURE: 'POST_FAILURE',
  FETCH_BUS_CHANGE: 'FETCH_BUS_CHANGE',
  FETCH_STOPS_ARRIVAL_TIMES: 'FETCH_STOPS_ARRIVAL_TIMES',
  FAILURE_FETCH_STOPS_ARRIVAL_TIMES: 'FAILURE_FETCH_STOPS_ARRIVAL_TIMES',
  FETCH_STUDENT_BOOKINGS: 'FETCH_STUDENT_BOOKINGS',
  FAILURE_STDUENT_BOOKINGS: 'FAILURE_STDUENT_BOOKINGS',
  FETCH_SUCCESS_ROUTE_SMS_BALANCE: 'FETCH_SUCCESS_ROUTE_SMS_BALANCE',
  FETCH_FAILURE_ROUTE_SMS_BALANCE: 'FETCH_FAILURE_ROUTE_SMS_BALANCE',
  FETCH_SUCCESS_RECIPENTS_COUNT: 'FETCH_SUCCESS_RECIPENTS_COUNT',
  FETCH_FAILURE_RECIPENTS_COUNT: 'FETCH_FAILURE_RECIPENTS_COUNT',
  FETCH_CHARGING_MONITORING: 'FETCH_CHARGING_MONITORING',
  FAILURE_CHARGING_MONITORING: 'FAILURE_CHARGING_MONITORING',
  FETCH_DRIVER_FAILURE: 'FETCH_DRIVER_FAILURE',
  FETCH_MULTIPLE_DRIVER_FAILURE: 'FETCH_MULTIPLE_DRIVER_FAILURE',
};

export default { transportColumns, TRANSPORT_EVENTS };
