import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box, Text, ResponsiveContext } from 'grommet';

import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import {
  getAuthToken,
  setAuthToken,
  getOrganisation,
  setOrganisation,
  getMyOrganisations,
} from 'granite-admin/utils/auth-singleton';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import useUtility from 'granite-admin/utils/useUtility';
import DashboardLayout from 'granite-admin/core/components/DashboardLayout/index';
// import Spinner from 'granite-admin/core/components/Spinner'
import Loader from 'granite-admin/core/components/Loader';
import useQuery from 'granite-admin/utils/useQuery';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { getCookie, setCookie } from 'granite-admin/utils/storage';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';
import { resetTenantAccess } from 'granite-admin/utils/auth-singleton';

import LinearLayout from './LinearLayout';
import { getMyProfile } from 'accounts/controllers/user';
import userApi from 'accounts/gateways/user_api';
import ParentLayout from './common/ParentLayout';
import UnauthorizedAccess from 'common/UnauthorizedAccess';
// import useWebSocketIO from 'granite-admin/utils/useWebSocketIO';
import useWebSocket from 'common/useWebSocket';
import { deleteAllCookies } from 'common/utils';
import { onConnectionOpen, onConnectionClose, checkMobileDevice } from 'common/CommonFunction.js';
import Home from 'pages/home';
import TwoFALayer from './common/TwoFALayer';

const serverURL = process.env.REACT_APP_MAIN_URL;
const FooterLabel = process.env.REACT_APP_FOOTER_LABEL;
const projectName = process.env.REACT_APP_PROJECT_NAME || 'RollCall';
const footerDisabled = process.env.REACT_APP_FOOTER_DISABLED || true;
const host = window.location.host;

const driverAccessibleScreens = ['/drivers', '/logout', '/profile', '/profile/basic-info', '/profile/settings'];
const SOCKET_SCREEN_ENDPOINTS = [
  '/route-mapping',
  '/operational-dashboard',
  '/global-dashboard',
  '/parent/mapbus',
  '/admin/transport',
];

function fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs) {
  getMyProfile()
    .then(response => {
      dispatch({
        type: 'accounts/user/UPDATE_PROFILE',
        data: response,
      });
      dispatch({
        type: 'organisations/UPDATE_ORGANISATIONS',
        data: response.organisations,
      });
      setSelectedOrganisation(response.currentOrganisation, dispatch, getSavedThemeConfigs, defaultThemeConfigs);
    })
    .catch(err => console.error(err));
  return (
    <Box align="center" height="100%" justify="center">
      <Loader />
    </Box>
  );
}

function setSelectedOrganisation(org, dispatch, getSavedThemeConfigs, defaultThemeConfigs) {
  if (org?.name) document.title = `${projectName} - ${org.name}`;
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: org,
  });
  dispatch({
    type: 'common/themeConfigs/UPDATE_THEME_CONFIGS',
    data: defaultThemeConfigs,
  });
  getSavedThemeConfigs(Object.keys(org)?.length < 1);
}

function TwoFABanner({ userProfile, dispatch, getSavedThemeConfigs, defaultThemeConfigs }) {
  const [show2FALayer, setShow2FALayer] = useState(false);
  // const redirectToSettings = useCallback(() => {
  //   if (pathname !== '/profile/basic-info') navigate('/profile/settings');
  // }, [navigate, pathname]);

  return (
    <>
      <Box direction="row" justify="center" gap="small">
        <Text
          alignSelf="center"
          color="#e18940"
          weight="bold"
          style={{
            position: 'relative',
            top: '-5px',
            zIndex: 1,
            maxWidth: '65%',
            textAlign: 'center',
            // cursor: pathname !== '/profile/basic-info' ? 'pointer' : 'default',
          }}
        >
          {`Your school's security is our top priority. Please enable Multi-Factor Authentication to help keep your
            information safe. `}
        </Text>
        <Text
          alignSelf="center"
          weight="bold"
          style={{
            position: 'relative',
            top: '-5px',
            zIndex: 1,
            maxWidth: '65%',
            textAlign: 'center',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => setShow2FALayer(true)}
          color="#2d6bab"
        >
          (Click here to setup MFA)
        </Text>
      </Box>
      {show2FALayer && (
        <TwoFALayer
          setShow2FALayer={setShow2FALayer}
          userProfile={userProfile}
          fetchUserProfile={fetchUserProfile}
          dispatch={dispatch}
          getSavedThemeConfigs={getSavedThemeConfigs}
          defaultThemeConfigs={defaultThemeConfigs}
        />
      )}
    </>
  );
}
const businessDomain = host.substring(
  0,
  host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
);
const domain = host.substring(
  host.indexOf('.') + 1,
  host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
);
const mainDomain =
  `${businessDomain}` === serverURL.replace('https://', '')?.replace('http://', '') ? businessDomain : domain;

const PrivateRoute = ({
  component: Component,
  layout,
  roles,
  userProfile,
  selectedOrganisation,
  dispatch,
  breadcrumbPath,
  organisations,
  path,
  heading,
  actions,
  tabs,
  addTabBtn,
  noDivider,
  permissions,
  noHeader,
  noSidebar,
  fields,
  entityName,
  ...rest
}) => {
  const runOnce = useRef(true);
  let authToken = getAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  let localOrganisation = getOrganisation();
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const { loadWebsocketInstance: loadNativeWebsocket, websocketInstance, closeConnection } = useWebSocket({
    onConnectionOpen,
    onConnectionClose,
    // onGetMessage,
  });
  const size = React.useContext(ResponsiveContext);
  const grandListner = useCallback(
    event => {
      switch (event.type) {
        case 'CLOSE_NATIVESOCKET_CONNECTION':
          closeConnection(true);
          break;
        case 'RECREATE_SOCKET_CONNECTION':
          {
            const pathname = window.location.pathname;
            if (websocketInstance?.readyState === WebSocket.CLOSED) {
              if (SOCKET_SCREEN_ENDPOINTS.includes(pathname) || pathname.includes('/my-boards/')) {
                window.location.reload();
              } else {
                userApi.myProfile();
                loadNativeWebsocket();
              }
              grandEmitter.emit('FETCH_NOTIFICATION_COUNT');
            }
          }
          break;
        default:
          break;
      }
    },
    [closeConnection, grandEmitter, loadNativeWebsocket, websocketInstance],
  );
  useUtility({ grandListner });

  const localMyOrganisations = getMyOrganisations();
  const memoChildProps = useMemo(() => ({ navigate, location }), [navigate, location]);

  const { getSavedThemeConfigs, defaultThemeConfigs } = useThemeConfigs();
  const { query: { embed, loadIframe, oAuthToken, orgId, dontMatch } = {}, pathname } = useQuery() ?? {};
  //TODO: add two more checks so that spinner is shown while organisation and selectedOrganisation are being set

  useEffect(() => {
    if (
      userProfile?.permissionsName?.includes('driver_menu_read') &&
      !userProfile.isSystemAdmin &&
      !driverAccessibleScreens.includes(location.pathname) &&
      (location.pathname === '/parents' || !location.pathname.includes('/parent'))
    ) {
      navigate('/drivers');
    } //third party redirect
    else if (
      (location.pathname === '/dashlets' || location.pathname.includes('/admin/rollover')) &&
      !localOrganisation
    ) {
      navigate('/business-board');
    } else if (location.pathname === '/dashlets' && localOrganisation) {
      navigate('/admin/transport');
    }
  }, [location.pathname, navigate, userProfile.permissionsName, userProfile.isSystemAdmin]);

  useEffect(() => {
    const script0 = document.createElement('script');
    if (
      authToken &&
      !window.location.pathname?.includes('logout') &&
      (location.pathname === '/parents' || !location.pathname.includes('/parent'))
    ) {
      if (document.getElementById('freshDeskId')) {
        window.showWidget();
      } else {
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        script0.id = 'freshDeskId';
        script0.text = `function hideWidget() {
        FreshworksWidget('hide');
        }
        function showWidget() {
          FreshworksWidget('show');
        }
        window.fwSettings={
        'widget_id':51000003880
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
        script1.src = 'https://aus-widget.freshworks.com/widgets/51000003880.js';
        script1.defer = true;
        script1.type = 'text/javascript';
        script2.setAttribute('type', 'text/javascript');
        document.head.appendChild(script0);
        document.head.appendChild(script1);
        document.head.appendChild(script2);
      }
    } else {
      window.hideWidget && window.hideWidget();
    }
  }, [authToken, location.pathname]);

  if (oAuthToken && oAuthToken !== authToken) {
    authToken = oAuthToken;
    setAuthToken(authToken);
  }
  if (orgId && orgId !== localOrganisation) {
    localOrganisation = orgId;
    setOrganisation(localOrganisation);
  }
  useEffect(() => {
    if (userProfile?.username && runOnce.current) {
      // setTimeout(() => grandEmitter.emit('LOAD_WEBSOCKET', {headers:{ organisation: userProfile?.currentOrganisation?.pk }}), 3000);
      // setTimeout(() => grandEmitter.emit('LOAD_WEBSOCKET'), 1000);
      loadNativeWebsocket();
      // loadWebsocketInstance(null, { organisation: userProfile?.currentOrganisation?.pk })
      runOnce.current = false;
    }
  }, [loadNativeWebsocket, userProfile]);

  const helperDeleteAllCookies = useCallback(() => {
    deleteAllCookies(mainDomain);
    removeAuthToken();
    resetTenantAccess();
    dispatch({ type: 'accounts/user/RESET_STATE' });
    if (closeConnection && websocketInstance.readyState === WebSocket.OPEN) {
      closeConnection(true);
    }
    navigate('/login');
  }, [dispatch, navigate, mainDomain]);
  if (authToken && !userProfile.username) {
    if (!getCookie('preURL')) {
      setCookie('preURL', JSON.stringify([window.location.hostname]), 1, mainDomain);
    }
    let isValidJSON = false;
    try {
      JSON.parse(getCookie('nodirectURL'));
      JSON.parse(getCookie('preURL'));
      isValidJSON = true;
    } catch {
      isValidJSON = false;
      helperDeleteAllCookies();
    }

    if (
      isValidJSON &&
      dontMatch !== 'yes' &&
      !JSON.parse(getCookie('preURL'))?.includes(window.location.hostname) &&
      // getCookie('preURL') !== window.location.hostname &&
      !JSON.parse(getCookie('nodirectURL'))
    ) {
      helperDeleteAllCookies();
    } else if (dontMatch !== 'yes') {
      try {
        let preURLList = JSON.parse(getCookie('preURL'));
        if (!preURLList?.includes(window.location.hostname)) {
          preURLList = [...preURLList, window.location.hostname];
          setCookie('preURL', JSON.stringify(preURLList), 1, mainDomain);
        }
      } catch (e) {
        console.log('error in parsing');
      }
      setCookie('nodirectURL', false, 1, mainDomain);
    }
    setTimeout(() => {
      if (pathname === '/operational-dashboard' && dontMatch === 'yes') navigate('/operational-dashboard');
    }, 1000);

    if (
      !(
        dontMatch !== 'yes' &&
        !JSON.parse(getCookie('preURL'))?.includes(window.location.hostname) &&
        !JSON.parse(getCookie('nodirectURL'))
      )
    )
      return fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
  }

  // if (!selectedOrganisation?.pk && localOrganisation && localOrganisation !== 'undefined') {
  //   setSelectedOrganisation({ pk: localOrganisation }, dispatch);
  // }

  if (!organisations && localMyOrganisations && localMyOrganisations !== 'undefined') {
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: localMyOrganisations,
    });
  }

  if (!userProfile.username) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={{ pathname: '/login', state: { from: location } }} />;
  }

  // TODO:
  // check if route is restricted by role
  // if (roles && roles.indexOf(userProfile.role) === -1) {
  // role not authorised so redirect to home page
  // return <Navigate to={{pathname: '/'}} />
  // }

  // if (userProfile.isSystemAdmin) {
  //   const url = window.location.origin;
  //   const currentURL = process.env.REACT_APP_MAIN_URL;
  //   const tenantAccess = getTenantAccess();
  //   if (url !== currentURL && !tenantAccess && !localOrganisation) {
  //     // resetAuthToken()
  //     localStorage.clear();
  //     window.location = `${currentURL}/login?access_token=${authToken}`;
  //     // window.location = `${'localhost:3000'}/login?access_token=${authToken}`;
  //   }
  // }

  const dashboardLayout = () => {
    const { isSystemAdmin, userTwoFA, permissionsName, twoFAOption } = userProfile;

    return (
      <Box
        fill
        direction={size === 'small' ? 'column' : undefined}
        pad={{ top: size === 'small' ? 'medium' : undefined }}
      >
        {!isSystemAdmin &&
          userTwoFA === 'true' &&
          twoFAOption.toLowerCase() === 'both' &&
          permissionsName.includes('user_preference_write') &&
          !embed &&
          !loadIframe &&
          !noSidebar && (
            <TwoFABanner
              userProfile={userProfile}
              fetchUserProfile={fetchUserProfile}
              dispatch={dispatch}
              getSavedThemeConfigs={getSavedThemeConfigs}
              defaultThemeConfigs={defaultThemeConfigs}
            />
          )}
        {/* {!isSystemAdmin && userTwoFA === 'true' && twoFA === 'false' && (
          <TwoFABanner navigate={navigate} pathname={pathname} permissions={permissionsName} />
        )} */}
        <DashboardLayout
          pathname={location.pathname}
          navigate={navigate}
          breadcrumbPath={breadcrumbPath}
          heading={heading}
          actions={actions}
          noDivider={noDivider}
          addTabBtn={addTabBtn}
          permissions={permissions}
          noHeader={embed || loadIframe ? true : noHeader}
          tabs={tabs}
          childProps={memoChildProps}
          component={Component}
          userEmail={userProfile.email}
          navigationLayout={userProfile.navigationLayout}
          noSidebar={embed || loadIframe ? true : noSidebar}
          fields={fields} //list of fields in the form
          entityName={entityName}
          footerLabel={FooterLabel}
          noFooter={embed || loadIframe ? true : footerDisabled}
          {...rest}
        />
      </Box>
    );
  };

  if (
    userProfile?.permissionsName?.includes('rollcall_parent_portal') &&
    userProfile?.permissionsName?.includes('rollcall_admin_portal')
  ) {
    if (path !== '/parents' && path.includes('/parent'))
      return checkMobileDevice() ? (
        <Home />
      ) : (
        <ParentLayout>
          <Component pathname={location.pathname} navigate={navigate} location={location} />
        </ParentLayout>
      );
    else return dashboardLayout();
  } else if (
    // !userProfile.isSystemAdmin &&
    userProfile.permissionsName.includes('rollcall_parent_portal') &&
    !(path !== '/parents' && path.includes('/parent'))
  ) {
    return checkMobileDevice() ? <Home /> : <Navigate to={{ pathname: '/parent', state: { from: location } }} />;
  } else if (
    // userProfile.isSystemAdmin &&
    userProfile.permissionsName.includes('rollcall_admin_portal') &&
    path !== '/parents' &&
    path.includes('/parent')
  ) {
    return <Navigate to={{ pathname: '/dashboard', state: { from: location } }} />;
  }
  if (
    window.location.host.substring(
      window.location.host.indexOf('.') + 1,
      window.location.host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
    ) === userProfile.currentOrganisation?.domain ||
    (!userProfile.permissionsName.includes('rollcall_parent_portal') &&
      !userProfile.permissionsName.includes('rollcall_admin_portal'))
  ) {
    return <UnauthorizedAccess />;
  } else if (!userProfile.isSystemAdmin && layout !== 'ParentLayout') {
    if (userProfile.organisations !== undefined && userProfile.organisations.length === 0 && path !== '/get-started') {
      return <Navigate to={{ pathname: '/get-started', state: { from: location } }} />;
    } else if (!selectedOrganisation?.pk && path !== '/get-started') {
      // show loader till default organisation is getting set in redux-state
      return (
        <Box align="center" height="100%" justify="center">
          <Loader />
        </Box>
      );
    }
  }
  if (layout === 'LinearLayout') {
    return (
      <LinearLayout>
        <Component pathname={location.pathname} navigate={navigate} location={location} />
      </LinearLayout>
    );
  }
  if (layout === 'ParentLayout') {
    return (
      <ParentLayout>
        <Component pathname={location.pathname} navigate={navigate} location={location} />
      </ParentLayout>
    );
  }
  return dashboardLayout();
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.string,
  roles: PropTypes.array,
  userProfile: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  dispatch: PropTypes.func,
  breadcrumbPath: PropTypes.array,
  organisations: PropTypes.array,
  path: PropTypes.string,
  heading: PropTypes.object,
  actions: PropTypes.object,
  tabs: PropTypes.array,
  addTabBtn: PropTypes.bool,
  noDivider: PropTypes.bool,
  permissions: PropTypes.array,
  noHeader: PropTypes.bool,
  noSidebar: PropTypes.bool,
  fields: PropTypes.array,
  entityName: PropTypes.any,
};

TwoFABanner.propTypes = {
  userProfile: PropTypes.object,
  fetchUserProfile: PropTypes.func,
  dispatch: PropTypes.func,
  getSavedThemeConfigs: PropTypes.func,
  defaultThemeConfigs: PropTypes.object,
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  organisations: OrganisationDucks.organisations(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
