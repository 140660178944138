// Lib imports
import _ from 'lodash/fp';

// Application Imports
import transportAPI from 'adminTransport/gateways/transport-api';
import { getOrganisation } from 'granite-admin/utils/auth-singleton';
import TransportConstants from './constants';
import GraniteError from 'granite-admin/utils/granite-error';

async function fetchTransportData(eventEmitter, currentDate, currentGroup) {
  let params = {
    route_date: currentDate,
    route_group_id: currentGroup,
    ordering: 'route__name',
    transport_screen: true,
    page_size: 100000,
  };
  try {
    const transportData = await transportAPI.getTransportData({ params });
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_ROUTE, transportData);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE);
    throw error;
  }
}

async function fetchBusAbsenteeList(eventEmitter, currentDate) {
  // try {
  const busAbsenteeData = await transportAPI.getBusAbsenteeData(currentDate);
  eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_BUS_ABSENTEE, busAbsenteeData);
  // } catch (error) {
  //   eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE);
  //   throw error;
  // }
}

async function fetchBusChangesList(eventEmitter, currentDate) {
  // try {
  const busChangeData = await transportAPI.getBusChangeData(currentDate);
  eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_BUS_CHANGE, busChangeData);
  // } catch (error) {
  //   eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE);
  //   throw error;
  // }
}

async function sendEmergencyEmail(values) {
  // const { eventEmitter } = values;
  // try {
  await transportAPI.sendEmergencyEmail(values);
  // } catch (error) {
  //   eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.POST_FAILURE);
  //   throw error;
  // }
}

async function addBusAbsence(values) {
  // try {
  await transportAPI.addBusAbsence(values);
  // } catch (error) {
  //   eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.POST_FAILURE);
  //   throw error;
  // }
}

async function addTraveller(values) {
  const { eventEmitter } = values;
  try {
    const stopList = await transportAPI.addTraveller(values);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.SAVE_SUCCESS, stopList);
    return stopList;
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.SAVE_FAILURE);
    throw error;
  }
}

// async function postMessageAllRoutes(values) {
//   const { eventEmitter } = values;
//   try {
//     const messageData = await transportAPI.postMessageAllRoutes(values);
//     eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.SAVE_SUCCESS, messageData);
//   } catch (error) {
//     eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.SAVE_FAILURE);
//     throw error;
//   }
// }

async function assignNewRoute(values) {
  const { eventEmitter, rowData } = values;
  try {
    const messageData = await transportAPI.assignNewRouteToStudent(values, rowData?.routeEventStudentBookingId);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.UPDATE_SUCCESS, messageData);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.UPDATE_FAILURE, error?.errors);
  }
}

async function deleteRoute(eventEmitter, pk, sendData, route_event_id, student_id) {
  const { data } = await transportAPI.deleteRoute(pk);
  const orgId = getOrganisation();
  if (sendData)
    sendData({
      action: 'onDeleteStudent',
      data: {
        organisation_id: orgId,
        route_event_id,
        student_id,
      },
    });
  return data;
}

async function fetchTransportStudentData(values) {
  const { eventEmitter, route_event_id, organisation_id } = values;
  try {
    const studentManifest = await transportAPI.fetchTransportStudentData({ data: { route_event_id, organisation_id } });
    eventEmitter.emit('onLoadStudentList', studentManifest);
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function fetchStopsArrivalTime(values) {
  const { eventEmitter, routeIds } = values;
  try {
    const arrivalTimes = await transportAPI.stopsArrivalTime(routeIds);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_STOPS_ARRIVAL_TIMES, arrivalTimes);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FAILURE_FETCH_STOPS_ARRIVAL_TIMES, error?.errors);
    throw error;
  }
}

async function fetchListStudentBookings(values) {
  const { eventEmitter, routeEventId } = values;
  try {
    const bookings = await transportAPI.listStudentBookings(routeEventId);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_STUDENT_BOOKINGS, bookings);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FAILURE_STDUENT_BOOKINGS, error?.errors);
  }
}

async function getRouteSmsBalance(values) {
  const { eventEmitter, organisationId } = values;
  try {
    const smsBalance = await transportAPI.getRouteSmsBalance(organisationId);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_SUCCESS_ROUTE_SMS_BALANCE, smsBalance);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE_ROUTE_SMS_BALANCE, error?.errors);
  }
}

async function messageAllRoutes(values) {
  const { eventEmitter, ...rest } = values;
  try {
    const response = await transportAPI.postMessageAllRoutes(rest);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_SUCCESS_RECIPENTS_COUNT, response);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE_RECIPENTS_COUNT, error?.errors);
  }
}

async function fetchChargingMonitoring(values) {
  const { eventEmitter, routeIds } = values;
  try {
    const arrivalTimes = await transportAPI.fetchChargingMonitoring(routeIds);
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_CHARGING_MONITORING, arrivalTimes);
    return arrivalTimes;
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FAILURE_CHARGING_MONITORING, error?.errors);
    throw error;
  }
}

/**
 * Transport screen - Get the single driver as per driver id.
 *
 * @param {object} - eventEmitter
 * @param {number} - driver id
 *
 * @returns {json} - json object of driver
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */

async function fetchSingleDriver(values) {
  const { eventEmitter, pk } = values;
  try {
    return await transportAPI.fetchSingleDriver(pk);
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_DRIVER_FAILURE, error?.errors);
  }
}

/**
 * Transport and OP screen - Get the multiple drivers as per route events.
 *
 * @param {object} - eventEmitter
 * @param {array} -Route event id's
 *
 * @returns {json} - array of drivers
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */

async function fetchMultipleDrivers(values) {
  const { eventEmitter, routeEventId } = values;
  const payload = {
    route_event_id: JSON.stringify([routeEventId]),
    page_size: 10000,
  };
  try {
    const response = await transportAPI.fetchMultipleDrivers(payload);
    return response?.results;
  } catch (error) {
    eventEmitter.emit(TransportConstants.TRANSPORT_EVENTS.FETCH_MULTIPLE_DRIVER_FAILURE, error?.errors);
  }
}

export {
  fetchTransportData,
  fetchBusAbsenteeList,
  fetchBusChangesList,
  sendEmergencyEmail,
  addBusAbsence,
  addTraveller,
  // postMessageAllRoutes,
  assignNewRoute,
  deleteRoute,
  fetchTransportStudentData,
  fetchStopsArrivalTime,
  fetchListStudentBookings,
  getRouteSmsBalance,
  messageAllRoutes,
  fetchChargingMonitoring,
  fetchSingleDriver,
  fetchMultipleDrivers,
};
