// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application Imports
import routeMessageAPI from '../gateways/routeMessage-api';
import { TOPNAVBAR_EVENTS } from './constants';

async function fetchRouteMessageLogs(eventEmitter, values) {
  if (_.isNil(values)) {
    throw new GraniteError('userData is invalid');
  }
  try {
    const messageLogs = await routeMessageAPI.getRouteMessageLogs(values);
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_LOGS_SUCCESS, messageLogs);
  } catch (error) {
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_LOGS_FAILURE);
    throw error;
  }
}

/**
 * Route Message and Route Mapping - Get the route events.
 *
 * @param {object} - eventEmitter
 * @param {string} - date
 * @param {object} - event group
 *
 * @returns {json} - array of route events
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */

async function fetchTransportData(eventEmitter, currentDate, currentGroup) {
  let str = JSON.stringify([currentDate]);
  let params = {
    route_date: str,
    route_group_id: currentGroup,
    ordering: 'route__name',
    page_size: 100000,
  };
  try {
    const transportData = await routeMessageAPI.getTransportData({ params });
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_ROUTE, transportData || []);
  } catch (error) {
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_ROUTE_FAILURE, error?.errors);
  }
}

export { fetchRouteMessageLogs, fetchTransportData };
