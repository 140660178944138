// Lib Imports
import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import { Box, Button, Calendar, Drop, MaskedInput } from 'grommet';
import { Clock, FormPreviousLink, FormNextLink, Schedule } from 'grommet-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'granite-admin/core/components/Select';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import {
  parseDate,
  formatDate,
  getDateInView,
  // isValidDate,
  setMonth,
  setYear,
  getYear,
  convertToTimezone,
  convertBoundsToTimezone,
} from './utils';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const calenderFixedHeight = 317;
const getPos = el => {
  const rect = el.getBoundingClientRect();
  var position = {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset,
  };
  return position;
};
const getDropAlignment = inputFieldRef => {
  const posOfField = getPos(inputFieldRef.current);
  const inputClientHeight = inputFieldRef?.current?.clientHeight;
  const topHeightAboveInput = posOfField.top;
  const heightLeftBelowInput = window.innerHeight - (Math.ceil(topHeightAboveInput) + inputClientHeight);
  if (topHeightAboveInput > calenderFixedHeight) {
    return { bottom: 'top' };
  }
  if (heightLeftBelowInput > calenderFixedHeight) {
    return { top: 'bottom' };
  }
  return { bottom: 'top' };
};

const StyledCalendar = styled(Calendar)`
  [role='grid'] {
    box-shadow: none;
  }
`;

const DropContent = ({
  date: initialDate,
  setInitialDate,
  onSelect,
  bounds,
  showAjacentDays,
  minYear,
  maxYear,
  showTime,
  userProfileTimeZone,
  ...rest
}) => {
  const calenderRef = useRef(null);
  useEffect(() => {
    const gridElement = calenderRef.current.querySelector('[role="grid"]');
    const handleMouseOver = () => {
      gridElement.focus();
    };
    if (gridElement) gridElement.addEventListener('mouseover', handleMouseOver);

    return () => {
      if (gridElement) gridElement.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);
  const [date, setDate] = useState(getDateInView(initialDate, bounds, userProfileTimeZone));
  const yearRange = useMemo(() => _.range(parseInt(minYear), parseInt(maxYear) + 1), [minYear, maxYear]);

  const yearOptions = useMemo(() => yearRange.map(option => ({ name: option, value: option })), [yearRange]);
  const months = useMemo(
    () => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    [],
  );
  const monthOptions = useMemo(() => months.map(option => ({ name: option, value: option })), [months]);

  const [time, setTime] = useState(formatDate(initialDate, 'p'));

  useEffect(() => {
    setDate(getDateInView(initialDate, bounds, userProfileTimeZone));
    setTime(formatDate(initialDate, 'p'));
  }, [initialDate, bounds, userProfileTimeZone]);

  const computeValue = (value, options) => {
    let findOption = options.find(option => option.value === value);

    return findOption?.value;
  };

  return (
    <Box
      pad="small"
      round="xsmall"
      elevation="small"
      background="white"
      border={{ color: 'black' }}
      overflow={{ horizontal: 'hidden', vertical: 'auto' }}
      gap="medium"
    >
      <StyledCalendar
        ref={calenderRef}
        animate={false}
        // date={date}
        date={date?.length === 10 ? new Date(`${date} 00:00:00`).toISOString() : new Date(date).toISOString()}
        onSelect={date => {
          onSelect(formatDate(new Date(date), 'P'), time);
        }}
        bounds={bounds}
        daysOfWeek
        showAdjacentDays={showAjacentDays}
        header={({ date: currentDate, locale, onPreviousMonth, onNextMonth, previousInBound, nextInBound }) => (
          <Box
            direction="row"
            align="center"
            justify="between"
            margin={{ bottom: 'small' }}
            border={{
              color: '#cccccc',
              size: '1px',
              style: 'solid',
              side: 'bottom',
            }}
          >
            <Button
              disabled={
                !previousInBound || (currentDate.getMonth() === 0 && currentDate.getFullYear() === parseInt(minYear))
              }
              onClick={onPreviousMonth}
            >
              <Box>
                <FormPreviousLink color="brand" />
              </Box>
            </Button>
            <Box direction="row" align="center">
              <Box width="80px">
                <Select
                  size="small"
                  plain
                  maxMenuHeight={200}
                  value={computeValue(formatDate(currentDate, 'MMM'), monthOptions)}
                  options={monthOptions}
                  onChange={({ value }) => {
                    setDate(setMonth(currentDate, months.indexOf(value)));
                  }}
                  labelKey="name"
                  valueKey={{ key: 'value', reduce: true }}
                  isClearable={false}
                />
              </Box>
              <Box width="90px">
                <Select
                  size="small"
                  plain
                  maxMenuHeight={200}
                  value={computeValue(getYear(currentDate), yearOptions)}
                  options={yearOptions}
                  onChange={({ value }) => {
                    setDate(setYear(currentDate, value));
                  }}
                  labelKey="name"
                  valueKey={{ key: 'value', reduce: true }}
                  isClearable={false}
                />
              </Box>
            </Box>
            <Button
              disabled={
                !nextInBound || (currentDate.getMonth() === 11 && currentDate.getFullYear() === parseInt(maxYear))
              }
              onClick={onNextMonth}
            >
              <Box>
                <FormNextLink color="brand" />
              </Box>
            </Button>
          </Box>
        )}
        {...rest}
      />
      {showTime && (
        <Box width="small" alignSelf="center">
          <MaskedInput
            mask={[
              {
                length: [1, 2],
                options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                regexp: /^1[1-2]$|^[0-9]$/,
                placeholder: 'hh',
              },
              { fixed: ':' },
              {
                length: 2,
                options: ['00', '15', '30', '45'],
                regexp: /^[0-5][0-9]$|^[0-9]$/,
                placeholder: 'mm',
              },
              { fixed: ' ' },
              {
                length: 2,
                options: ['AM', 'PM'],
                regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                placeholder: 'ap',
              },
            ]}
            name="maskedInput"
            value={time}
            icon={<Clock />}
            reverse
            onKeyDown={e => e.keyCode !== 8 && e.preventDefault()}
            onChange={e => setTime(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

DropContent.propTypes = {
  date: PropTypes.instanceOf(Date),
  setInitialDate: PropTypes.func,
  onSelect: PropTypes.func,
  bounds: PropTypes.array,
  showAjacentDays: PropTypes.bool,
  minYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showTime: PropTypes.bool,
};

// const availableFormats = ['yyyy-MM-dd', 'dd-MM-yyyy'];

const Datepicker = ({
  initialDate,
  onChange,
  showTime,
  bounds: initialBounds,
  minYear,
  maxYear,
  customFormat,
  viewDateFormat,
  disableInput,
  disableDatepicker,
  dateInputIcon,
  customTimeZone = false,
  isconvertBoundsToTimeZone = true,
  ...rest
}) => {
  const profileTimeZone = useSelector(({ accounts }) => accounts?.user?.profile?.timezone);
  const userProfileTimeZone = customTimeZone ? customTimeZone : profileTimeZone;
  const defaultIsVisible = false;
  const [isVisible, setIsVisible] = useState(defaultIsVisible);
  const [date, setDate] = useState(
    initialDate || (userProfileTimeZone ? convertToTimezone(new Date(), userProfileTimeZone) : undefined),
  );
  const [inputValue, setInputValue] = useState('');
  const config = useContext(ConfigContext);
  const childRef = useRef();
  const bounds = initialBounds
    ? isconvertBoundsToTimeZone
      ? convertBoundsToTimezone(initialBounds, userProfileTimeZone)
      : initialBounds
    : [`${minYear}-01-01`, `${maxYear}-12-31`];
  const datepickerImage = useMemo(() => {
    return dateInputIcon ? (
      dateInputIcon
    ) : config.datePickerIcon ? (
      config.datePickerIcon
    ) : (
      <Schedule size="18px" color="brand" />
    );
  }, [config, dateInputIcon]);
  useEffect(() => {
    if (!initialDate) {
      setInputValue('');
    }
  }, [initialDate]);

  let customDateFormat = useMemo(() => {
    if (customFormat) return customFormat;
    else return `P${showTime ? ' p' : ''}`;
  }, [customFormat, showTime]);

  const renderDateFormat = useMemo(() => {
    if (viewDateFormat) {
      return showTime ? `${viewDateFormat} p` : viewDateFormat;
    } else {
      return customDateFormat;
    }
  }, [viewDateFormat, showTime, customDateFormat]);

  const inputMask = useMemo(() => {
    const dd = {
      length: [1, 2],
      regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
      placeholder: 'dd',
    };
    const mm = {
      length: [1, 2],
      regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
      placeholder: 'mm',
    };
    const yyyy = {
      length: 4,
      //regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
      placeholder: 'yyyy',
    };
    let separator = null;
    let maskFormat = renderDateFormat.split(' ')[0];
    if (maskFormat.split('-').length === 3) {
      maskFormat = maskFormat.split('-');
      separator = { fixed: '-' };
    } else {
      maskFormat = maskFormat.split('/');
      separator = { fixed: '/' };
    }
    let mask = [];
    maskFormat.forEach(i => {
      if (i.toLowerCase() === 'mm') mask.push(mm);
      else if (i.toLowerCase() === 'dd') mask.push(dd);
      else if (i.toLowerCase() === 'yyyy') mask.push(yyyy);
      if (mask.length === 1 || mask.length === 3) mask.push(separator); //add separator after first & third entry
    });
    if (showTime) {
      mask.push([
        { fixed: ' ' },
        {
          length: [1, 2],
          regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
          placeholder: 'hh',
        },
        { fixed: ':' },
        {
          length: 2,
          regexp: /^[0-5][0-9]$|^[0-9]$/,
          placeholder: 'mm',
        },
        { fixed: ' ' },
        {
          length: 2,
          regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
          placeholder: 'AM',
        },
      ]);
    }
    return mask;
  }, [showTime, renderDateFormat]);

  // const dateFormat = `P${showTime ? ' p' : ''}`;
  useEffect(() => initialDate && setInputValue(formatDate(initialDate, renderDateFormat)), [
    initialDate,
    renderDateFormat,
  ]);

  const handleSelect = (date, time) => {
    const newDate = new Date(`${date}, ${time}`);
    setDate(newDate);

    onChange(customDateFormat !== null ? format(newDate, customDateFormat) : newDate);
    setIsVisible(false);
  };

  const handleChange = event => {
    if (disableInput) {
      event.preventDefault();
      return;
    }
    const value = event.target.value;
    setInputValue(value);
    if (!value) setDate(undefined);
    const date = parseDate(value, renderDateFormat, bounds);
    if (date) {
      setDate(date);
    }
  };

  const handleKeyDown = event => {
    if (disableInput && (event.keyCode === 8 || event.keyCode === 46)) {
      setInputValue('');
      setDate(undefined);
    }
    if (event.keyCode === 13) handleClickOutside();
  };

  const handleClickOutside = () => {
    onChange(customDateFormat !== null && date ? format(date?.length === 10 ? new Date(`${date} 00:00:00`) : new Date(date), customDateFormat) : date);
    setIsVisible(false);
  };

  const handleBlur = e => {
    let inputDate = convertToJSDate();
    if (inputDate) {
      setDate(inputDate);
      setInputValue(formatDate(inputDate, renderDateFormat));
    } else {
      setInputValue(formatDate(date, renderDateFormat));
    }
  };
  const convertToJSDate = () => {
    let ret = parseDate(inputValue, customDateFormat, bounds);
    let sep = '-';
    if (renderDateFormat.includes('-')) {
      sep = '-';
    } else if (renderDateFormat.includes('/')) {
      sep = '/';
    }
    if (renderDateFormat === `dd${sep}MM${sep}yyyy` || renderDateFormat === `dd${sep}MM${sep}yyyy p`) {
      //will convert dd/MM/yyyy to MM/dd/yyyy for parsing
      let datePart = inputValue.split(' ')[0];
      let dateSubParts = datePart.split(sep); // taking out dd, MM & yyyy component
      if (dateSubParts[0] && dateSubParts[1] && dateSubParts[2] && dateSubParts[2].length === 4) {
        // converting to MM/dd/yyyy
        let fDate = `${dateSubParts[1]}${sep}${dateSubParts[0]}${sep}${dateSubParts[2]}${inputValue.substring(
          datePart.length,
        )}`;

        return parseDate(fDate, customDateFormat, bounds);
      }
    }
    return ret;
  };

  const handleFocus = e => {
    e.preventDefault();
    if (!isVisible) setIsVisible(true);
  };

  return (
    <>
      <span onClick={() => !disableDatepicker && setIsVisible(true)} ref={childRef}>
        <MaskedInput
          mask={inputMask}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          icon={datepickerImage}
          reverse
          disabled={disableDatepicker}
        />
      </span>
      {childRef.current && isVisible && (
        <Drop
          stretch={false}
          align={getDropAlignment(childRef)}
          target={childRef.current}
          overflow="visible"
          onClickOutside={handleClickOutside}
          plain
        >
          <DropContent
            date={date}
            setInitialDate={setDate}
            onSelect={handleSelect}
            showTime={showTime}
            bounds={bounds}
            minYear={minYear}
            maxYear={maxYear}
            userProfileTimeZone={userProfileTimeZone}
            {...rest}
          />
        </Drop>
      )}
    </>
  );
};

Datepicker.defaultProps = {
  initialDate: undefined,
  onClose: () => { },
  showAjacentDays: true,
  bounds: undefined,
  minYear: new Date().getFullYear() - 20,
  maxYear: new Date().getFullYear() + 20,
  showTime: false,
  disableInput: false,
  disableDatepicker: false,
};

Datepicker.propTypes = {
  initialDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onClose: PropTypes.func,
  showAjacentDays: PropTypes.bool,
  bounds: PropTypes.array,
  yearRange: PropTypes.array,
  minYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  showTime: PropTypes.bool,
  customFormat: PropTypes.string,
  viewDateFormat: PropTypes.string,
  disableInput: PropTypes.bool,
  disableDatepicker: PropTypes.bool,
  dateInputIcon: PropTypes.any,
  customTimeZone: PropTypes.any,
  isconvertBoundsToTimeZone: PropTypes.bool,
};
export default Datepicker;
